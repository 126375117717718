import { HTTP } from "./configuration";
import userStore from "@/store/index.js";

export default {
    all: async () => {
        return await HTTP.get("comunities", {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
    findOne: async (id) => {
        return await HTTP.get(`comunities/${id}`, {
          headers: {
            Authorization: `Bearer ${userStore.getters.getUser.jwt}`
          }
        });
    },
    saveOrUpdate: async (comunity) => {
        const data = {
            Name: comunity.name,
        }

        if (!comunity.id)
            return await HTTP.post("comunities", { data }, {
                headers: {
                    Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                }
            });

        return await HTTP.put(`comunities/${comunity.id}`, { data }, {
                headers: {
                    Authorization: `Bearer ${userStore.getters.getUser.jwt}`
                }
            });
    },
    remove: async (id) => {
        return await HTTP.delete(`comunities/${id}`, {
            headers: {
                Authorization: `Bearer ${userStore.getters.getUser.jwt}`
            }
        });
    },
}