export default {
    isFieldsAreNull: (data) => {
        let isValid = true;

        for (let [item] of Object.entries(data)) {            
            if (data[item] !== null)
                isValid = false;
        }

        return isValid;
    }
}